<template>
    <div>
        <title-bar :title="$t('navigations.customers')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['filterSelect', 'search', 'addBtn', 'perPage']"
                :addBtnText="$t('messages.customerCreate')"
                :addBtnLink="$helper.getAddUrl('customers')"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterSelectChange="filterSelectChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:customer="data">
                    {{ data.value.customer }}
                </template>
                <template v-slot:branch="data">
                    <div class="vs__selected-options"
                         v-if="data.value.branches && data.value.branches.length">
                        <span class="vs__selected" v-for="branch in data.value.branches" :key="branch">
                          {{ branch.name }}
                        </span>
                    </div>
                </template>
                <template v-slot:station="data">
                    <div class="vs__selected-options"
                         v-if="data.value.stations && data.value.stations.length">
                        <span class="vs__selected" v-for="station in data.value.stations" :key="station">
                          {{ station.name }}
                        </span>
                    </div>
                </template>
                <template v-slot:typeOfAC="data">
                    {{ data.value.customerData.typeOfAC }}
                </template>
                <template v-slot:opsContact="data">
                    {{ data.value.customerData.opsContact }}
                </template>
                <template v-slot:email="data">
                    {{ data.value.email }}
                </template>
                <template v-slot:phone="data">
                    {{ data.value.companyTelephone }}
                </template>
                <template v-slot:contracted="data">
                    <b-badge :variant="data.value.customerData.contracted ? 'success' : 'danger'">{{
                            data.value.customerData.contracted ? "YES" : "NO"
                        }}
                    </b-badge>
                </template>
                <template v-slot:enabled="data">
                    <b-badge :variant="data.value.enabled ? 'success' : 'danger'">{{
                            data.value.enabled ? "ACTIVE" : "INACTIVE"
                        }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn :path="$helper.getEditUrl('customers', data.value.id)"/>
                        <delete-btn @pressDelete="deleteEmployee(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "AdminHolidayListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar,
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            enabled: true,
            filterOption: {
                id: true,
                label: "Active"
            },
            filterOptions: [
                {
                    id: true,
                    label: "Active"
                },
                {
                    id: false,
                    label: "Inactive"
                },
                {
                    id: "all",
                    label: "All"
                }
            ],
            sortable: {
                order: "customer",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("forms.name"),
                    field: "customer",
                    sortable: true,
                },
                {
                    label: this.$t("forms.branch"),
                    field: "branch",
                    sortable: true,
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.typeOfAC"),
                    field: "typeOfAC",
                    sortable: false,
                },
                {
                    label: 'OPS Contact',
                    field: "opsContact",
                    sortable: false,
                },
                {
                    label: this.$t("forms.contracted"),
                    field: "contracted",
                    sortable: true,
                },
                {
                    label: this.$t("forms.status"),
                    field: "enabled",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                role: "ROLE_CUSTOMER",
                enabled: this.enabled,
            };

            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.$Users.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === "branch" || order === "station") {
                this.sortable.order = order + ".name";
            }

            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteEmployee(url) {
            this.deleteByUrl(
                this.$Users,
                url,
                this.$t("messages.customerDeleted"),
                null,
                this.load,
                this.error
            );
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        filterSelectChange(enabled) {
            this.enabled = enabled.id
            if (enabled.id === "all") {
                this.enabled = [true, false]
            }
            this.load()
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
</style>
